export const SideDrawerData = [      
        {
        title:'Ana Sayfa',
        path:'/',
    },
    {
        title:'Ürünler',
        path:'/products',
        
    },
    {
        title: 'Bize Ulaşın',
        path: '/contact-us',
       
    },
];
