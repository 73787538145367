export const MenuItems = [
      {
        name:'Ana Sayfa',
        path:'/',
        cName:'nav-links'
    },
    {
        name:'Ürünler', 
        path:'/products',
        cName:'nav-links'
    },
    {
        name:'Bize Ulaşın',
        path:'/contact-us',
        cName:'nav-links'
    },
  
]

